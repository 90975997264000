<template>
  <div class="newEnglish">
    <main>
      <div v-for="(item, index) in 4" :key="index" @click="goutils(textArr[index])">
        <span>{{textArr[index]}}</span>
        <img :src="imgArr[index]" alt="">
      </div>
    </main>
    <Header :header_data="header_data"/>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/zjfheader.vue";
import Footer from "../components/footer.vue";
import { getCurrentInstance } from 'vue'
import { getTpZList } from '@/api/student.js'
import { onMounted, reactive, toRefs } from 'vue-demi';
import { ElMessage } from 'element-plus';
import img1 from '../../../assets/student/newEnglish1.jpeg'
import img2 from '../../../assets/student/newEnglish2.png'
import img3 from '../../../assets/student/newEnglish3.jpeg'
import img4 from '../../../assets/student/newEnglish4.jpeg'
export default {
  components: {
    Header,
    Footer,
  },
  setup() {
    const { appContext } = getCurrentInstance()
    const that = appContext.config.globalProperties;
    const header_data = [
      {title: '图谱英语', path: ''},
    ]
    const data = reactive({
      jxInfo: {},
      chapterList: [],
      imgArr: [img1,img2,img3,img4],
      textArr: ['新概念英语第一册','新概念英语第二册', '新概念英语第三册', '新概念英语第四册'],
    })
    // 点击章节跳转
    const goutils = (row) => {
      let arr = []
      data.chapterList.forEach(item => {
        arr.push(item.mc)
      })
      if(arr.includes(row)) {
        let obj = data.chapterList.find(ele => ele.mc == row)
        that.$router.replace({name: 'units', query: {zid: obj.id, zmc: obj.mc}})
      } else {
        ElMessage({
          type: 'warning',
          message:`暂无权限，请联系管理员：4000-390308`,
        })
      }
    }
    onMounted(() => {
      getTpZList({}).then(res => {
        if(res.success) {
          res.result.sort((a,b) => { return a.id - b.id })
          data.chapterList = res.result
        } else {
          ElMessage({
            message: res.resultDesc,
            type: 'warning'
          })
        }
      })
    })
    return {
      goutils,
      header_data,
      ...toRefs(data),
    }
  },
}
</script>
<style lang="scss" scoped>
.newEnglish{
  width: 100%;
  height: 100%;
  background: url("../../../assets/student/mnemonic.png") no-repeat;
  background-size: 100% 100%;
  main{
    width: 86%;
    height: 55%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    div {
      float: left;
      width: 21.5%;
      margin-right: 4%;
      height: 100%;
      background: url("../../../assets/student/newEnglishimg.png") no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding-top: 2vw;
      font-size: 1.7vw;
      color: #fff;
      text-align: center;
      font-family: PingFang SC;
      cursor: pointer;
      img{
        margin: 0.8vw auto;
        border: 2px solid #fff;
        display: block;
        border-radius: 20px;
        width: 70%;
        height: calc(100% - 6.5vw);
      }
    }
    div:last-child{
      margin: 0;
    }
  }
}
</style>